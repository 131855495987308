<template>
  <div>
    <b-container fluid class="px-0 py-0">
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line px-2 py-1 bg-white">
        <b-row>
          <b-col
            class="align-self-center"
            cols="3"
          >
            <div
              style="cursor: pointer;"
              @click="redirectPage"
            >
              <feather-icon
                size="24"
                class="text-dark fw-bold-800"
                icon="XIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Batal</span>
            </div>
          </b-col>
          <b-col
            class="align-self-center"
            cols="6"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Edit Transaksi {{ result.invoice_number || '-' }}
            </h1>
          </b-col>
          <b-col
            class="align-self-center text-right"
            cols="3"
          >
            <b-button
              id="button--save__transaction"
              class="px-2 py-1 pt-1 border-8"
              :disabled="!formPayloadBatch.customer_uuid && cashTotalPayment > total"
              @click="saveTransaction"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
    </b-container>
    <b-container fluid class="mt-5 pt-5">
      <b-row class="mt-3 justify-content-center">
        <b-col
          cols="6"
          md="4"
          lg="4"
        >
          <div class="mb-2">
            <b-form-group class="custom__form--input mb-0">
              <label for="date-creation">Tanggal Transaksi</label>
              <b-form-input
                id="date-creation"
                v-model="formPayloadBatch.date"
                class="custom__input pt-1"
                type="date"
                placeholder="DD/MM/YYYY"
                required
              />
            </b-form-group>
          </div>
          <div class="mb-2">
            <b-form-group class="custom__form--input mb-0">
              <label for="">Pilih Pelanggan <span class="text-danger" v-if="cashTotalPayment > total">*</span></label>
              <v-select
                v-model="formPayloadBatch.customer_uuid"
                label="title"
                :options="options"
                :reduce="option => option.label"
                placeholder="Pilih Pelanggan"
                @search="onSearch"
              />
            </b-form-group>
          </div>
          <div class="mb-2">
            <b-form-group class="custom__form--input mb-0">
              <label for="">Metode Pengiriman</label>
              <v-select
                v-model="formPayloadBatch.delivery_pickup"
                label="title"
                :options="[{ title: 'Ambil Sendiri', label: 'pickup' }, { title: 'Dikirim', label: 'delivery' }]"
                :reduce="option => option.label"
                placeholder="Pilih Pengiriman"
              />
            </b-form-group>
          </div>
          <div class="mb-2">
            <div class="d-flex align-items-center" v-for="(item, index) in additional_costs" :key="index">
              <b-form-group class="custom__form--input mr-1">
                <label for="value-diskon">Nama Biaya</label>
                <input
                  :id="`nama-biaya-${index}`"
                  type="text"
                  v-model="additional_costs[index].additional_fee_note"
                  class="custom__input pl-2 w-100"
                  placeholder="Masukan Nama Biaya"
                >
              </b-form-group>
              <b-form-group class="custom__form--input">
                <label for="value-diskon">Nominal Biaya</label>
                <input
                  :id="`nominal-biaya-${index}`"
                  type="text"
                  :value="additional_costs[index].additional_fee_amount"
                  class="custom__input pl-2 w-100"
                  placeholder="Masukan Nominal Biaya"
                  @input="getRupiah(index)"
                  @change="getRupiah(index)"
                >
              </b-form-group>
              <b-button id="button--hapus__biaya" class="p-1 ml-25" variant="outline-danger" @click="removeItem(index)">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
            <b-button id="button--tambah__biaya" class="w-100" variant="outline-dark" @click="repeateAgain" v-if="additional_costs.length < 3">
              + Tambah Biaya Tambahan
            </b-button>
          </div>
        </b-col>        
      </b-row>
      <b-row class="mt-3 justify-content-center">
        <b-col
          cols="6"
          md="4"
          lg="4"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="text-dark text-darken-7 fw-bold-700 mb-0" v-if="result && result.items">
              Total Pembelian <span class="ml-1">{{ result.items.length || 0 }}</span>
            </h6>
            <!-- <b-button
              id="button--add__product"
              v-b-modal.modal-add-product
              :disabled="!formPayloadBatch.customer_uuid"
              class="border-8"
            >
              + Tambah Produk
            </b-button> -->
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col
          cols="8"
          md="5"
          lg="5"
        >
          <b-container class="cart__product--container">
            <b-card
              class="p-0 mb-0"
              no-body
            >
              <b-card-body class="p-2">
                <b-media
                  v-for="(item, index) in result.items"
                  :key="index"
                  right-align
                  vertical-align="top"
                  class="text-left p-0"
                  :class="result && result.items ? 'mb-3' : ''"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <h4 class="h6 text-dark mb-0">
                      {{ item.product && item.product.name || '-' }}
                    </h4>
                    <div class="w-50 d-flex justify-content-end">
                      <div class="w-50">
                        <div class="spinner__button spinner__button--addproduct">
                          <div
                            id="decrease"
                            class="value-button text-dark font-weight-bolder"
                            value="Decrease Value"
                            @click="decreaseValue(index, item.uuid)"
                          >
                            -
                          </div>
                          <input
                            :id="`number-${index}`"
                            class="qty text-dark font-weight-bolder"
                            type="number"
                            :value="formQty[index].qty || 0"
                            @input="changeValue(index, item.uuid)"
                          >
                          <div
                            id="increase"
                            class="value-button text-dark font-weight-bolder"
                            value="Increase Value"
                            @click="increaseValue(index, item.uuid)"
                          >
                            +
                          </div>
                        </div>
                      </div>
                      <!-- <div
                        :id="`tour-delete-cart-${index}`"
                        class="align-self-center ml-1"
                      >
                        <b-button
                          class="bg-white border-rounded p-0"
                          style="box-shadow: 0px 7.2px 14.4px rgba(0, 0, 0, 0.06);height:32px;width:32px"
                        >
                          <feather-icon
                            class="text-danger"
                            icon="XIcon"
                            @click="removeCart(item.uuid)"
                          />
                        </b-button>
                      </div> -->
                    </div>
                  </div>
                  <h6 class="text-dark size24">
                    {{ item.total | formatAmount }} <span
                      v-if="item.discount_total > 0"
                      class="text-dark text-darken-5 font-weight-light size12"
                      style="text-decoration: line-through"
                    >{{ item.sub_price | formatAmount }}</span>
                    <span v-if="item.discount_total > 0">&nbsp;<b-badge
                      variant="danger"
                      class="border-15 px-1 size12"
                      style="padding-top: 5px;padding-bottom: 5px;"
                    >Diskon</b-badge></span>
                  </h6>
                  <div class="d-flex justify-content-between">
                    <div class="w-50">
                      <b-button
                        size="sm"
                        class="bg-white size12"
                        @click="editItem(item.product.uuid, item, item.uuid)"
                      >
                        <b-spinner
                          v-if="isLoadingEdit && item.uuid === id"
                          small
                          variant="primary"
                          style="margin-right: 0.5rem;"
                        />
                        <feather-icon
                          v-else
                          size="12"
                          icon="Edit2Icon"
                          style="margin-right: 0.5rem;"
                        />
                        <span>Ubah rincian</span>
                      </b-button>
                    </div>
                  </div>
                </b-media>
              </b-card-body>
            </b-card>
            <div class="floating__button">
              <AddDiscountTransaction />
            </div>
          </b-container>
          <ModalAddProduct
            @id="getId"
            @formPayload="getFormPayloadFromAddProduct"
            @typeWarehouse="getTypeWarehouseFromAddProduct"
          />
          <ModalEditProduct
            :key="2"
            :modal-key="'product'"
            :form-data="formPayload"
            :create-item-to-cart="createItemToCart"
            :messages="messages"
            :loading="isLoading"
            :type-warehouses="typeWarehouse"
            @typeWarehouse="getTypeWarehouse"
            @payload="getPayload"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BContainer, BButton, BRow, BCol, BCard, BMedia, BCardBody, BSpinner, BBadge, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { checkPermission, successNotification, errorNotification, preformatFloat } from '@/auth/utils'
import moment from 'moment'

import ModalEditProduct from '@/components/Transaction/Modal/EditProduct.vue'
import ModalAddProduct from '@/components/Transaction/Modal/AddProduct.vue'
import CardListProduct from '@/components/Cashier/Cart/CartListProduct.vue'
import AddDiscountTransaction from '@/components/AddDiscountTransaction.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    ModalAddProduct,
    AddDiscountTransaction,
    ModalEditProduct,
    BCard,
    BMedia,
    BCardBody,
    BSpinner,
    BBadge,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification, preformatFloat
    }
  },
  data() {
    return {
      isLoading: false,
      isLoadingEdit: false,
      result: {},
      resultDetail: {},
      formQty: [],
      messages: '',
      id: '',
      typeWarehouse: '',
      formPayloadBatch: {
        discount_type: '',
        discount_value: 0,
        items: [],
        date: '',
        customer_uuid: '',
        delivery_pickup: '',
      },
      formPayload: {
        delivery_numbers_uuid: [],
        po_so_uuid: '',
        product_uuid: '',
        warehouse_uuid: '',
        qty: 0,
        price: 0,
        discounts: [],
        is_bonus: 0,
      },
      additional_costs: [],
      options: [],
      cashTotalPayment: 0,
      total: 0,
    }
  },
  watch: {
    'formPayloadBatch.customer_uuid': function (value) {
      if (value) {
        this.getDetail(value)
      }
    },
    // additional_costs: {
    //   handler(value) {
    //     let cashTotalPayment = parseFloat(this.$route.query.total) || 0
    //     let total = 0
    //     this.formPayload.additional_fees = value
    //     this.$store.commit('cart/SET_FORM_ADDITIONAL_COST', this.additionalCost)
    //     for (let index = 0; index < value.length; index++) {
    //       const element = value[index];
    //       total += parseFloat(preformatFloat(element.additional_fee_amount)) || 0
    //     }
    //     cashTotalPayment += total
    //     this.$store.commit('cart/SET_TOTAL_TRANSAKSI', cashTotalPayment)
    //     localStorage.setItem('cash_total_payment', cashTotalPayment)
    //     localStorage.setItem('additional_cost', JSON.stringify(value))
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.getActiveCart()
    this.getCustomer()
  },
  methods: {
    repeateAgain() {
      this.additional_costs.push({
        additional_fee_amount: 0,
        additional_fee_note: '',
      })
    },
    removeItem(index) {
      this.additional_costs.splice(index, 1)
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.searchItem(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.options = []
          this.getCustomer()
          loading(false)
        }, 300)
      }
    },
    searchItem: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('customer/getData', {
        params: {
          search: escape(search),
        },
      })
        .then(response => {
          vm.options = []
          response.data.data.data.forEach(item => {
            vm.options.push({
              label: item.uuid,
              title: item.name,
            })
          })
          const option = vm.getUniqueListBy(vm.options, 'label')
          vm.options = option
          loading(false)
        })
    }, 300),
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    async getDetail(uuid) {
      this.$store.commit('customer/setUpdateData', false)
      localStorage.setItem('payment_customer_uuid', uuid)
      this.$store.dispatch('customer/getDetail', {
        uuid,
      })
        .then(result => {
          // this.results = result.data.data
          setTimeout(() => {
            this.options.unshift({
              label: result.data.data.uuid,
              title: result.data.data.name,
            })
            const option = this.getUniqueListBy(this.options, 'label')
            this.options = option
            this.isLoading = false
          }, 500)
          // this.saldo = result.data.data.wallet_balance
        }).catch(err => {
          this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    async getCustomer() {
      this.options = []
      this.isLoading = true
      this.$store.dispatch('customer/getData', {
        params: {
          per_page: 30,
        },
      })
        .then(result => {
          result.data.data.data.forEach(item => {
            this.options.push({
              label: item.uuid,
              title: item.name,
            })
          })
          this.isLoading = false
        }).catch(err => {
          this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    async saveTransaction() {
      this.isLoading = true
      const form = this.preparePayloadBatch()
      form.append('_method', 'PATCH')
      form.append('date', this.formPayloadBatch.date)
      form.append('delivery_pickup', this.formPayloadBatch.delivery_pickup)
      if (this.formPayloadBatch.customer_uuid) {
        form.append('customer_uuid', this.formPayloadBatch.customer_uuid)
      }
      if (this.$store.state.cart.discountType) {
        form.append('discount_value', this.$store.state.cart.discountCart)
        form.append('discount_type', this.$store.state.cart.discountType)
      }

      await this.$store.dispatch('order/postData', {
        uuid: this.$route.params.id,
        payload: form,
      }).then(() => {
        this.isLoading = false
        successNotification(this, 'Success', 'Transaksi berhasil diubah')
        this.getData()
        this.$router.push({ name: 'transaction.index' })
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    getId(value) {
      this.id = value
    },
    getFormPayloadFromAddProduct(value) {
      this.formPayload = value
    },
    getTypeWarehouseFromAddProduct(value) {
      this.typeWarehouse = value
    },
    getTypeWarehouse(value) {
      this.typeWarehouse = value
    },
    getPayload(value) {
      this.formPayload = value
    },
    async getData() {
      await this.$store.dispatch('order/getData', {
        uuid: `${this.$route.params.id}/detail` || '',
        params: '',
      }).then(result => {
        const item = result.data.data
        this.$store.commit('cart/setDiscountCart', item.discount_value)
        this.$store.commit('cart/setDiscountType', item.discount_type)
        this.$store.commit('cart/setDiscountTotal', item.discount_total)
        this.result = item
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getActiveCart() {
      await this.$store.dispatch('order/getData', {
        uuid: `${this.$route.params.id}/detail` || '',
        params: '',
      }).then(result => {
        const item = result.data.data
        this.$store.commit('cart/setDiscountCart', item.discount_value)
        this.$store.commit('cart/setDiscountType', item.discount_type)
        this.$store.commit('cart/setDiscountTotal', item.discount_total)
        this.formPayloadBatch.date = moment(item.date).format('YYYY-MM-DD')
        this.formPayloadBatch.customer_uuid = item.customer?.uuid
        this.formPayloadBatch.delivery_pickup = item.delivery_pickup
        this.additional_costs = item.additional_fees
        this.cashTotalPayment = item.total
        this.total = item.total

        for (let index = 0; index < item.items.length; index++) {
          const element = item.items[index]
          this.formPayloadBatch.items.push(
            {
              item_uuid: element.uuid,
              delivery_numbers_uuid: element.delivery_numbers.map(el => ({ title: el.delivery_number_note, label: el.uuid })) || [],
              po_so_uuid: element.po_so?.uuid || null,
              product_uuid: element.product.uuid,
              warehouse_uuid: element.warehouse ? element.warehouse?.uuid : element.supplier?.uuid,
              qty: element.qty,
              price: element.sub_price,
              discounts: element.discounts,
              is_bonus: element.is_bonus === false ? 0 : 1,
            },
          )
          this.formQty.push(
            {
              qty: element.qty,
            },
          )
        }
        this.result = item
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async editItem(id, product, itemId) {
      this.id = itemId
      this.isLoadingEdit = true
      this.$store.commit('cart/setIsEdit', true)
      // this.formPayload.product_uuid = id
      if (itemId === this.id) {
        await this.$store.dispatch('cashier/getData', {
          uuid: `/${id}`,
          params: '',
        }).then(result => {
          const item = result.data.data
          this.formPayload = {
            is_bonus: product.is_bonus === false ? 0 : 1,
            product_uuid: product.product.uuid,
            price: product.sub_price,
            discounts: product.discounts,
            qty: product.qty,
            warehouse_uuid: item.stock_in_warehouse[0].uuid || '',
          }
          this.stock = item.stock_in_warehouse[0].stock || 0
          this.typeWarehouse = item.stock_in_warehouse[0].type || ''
          this.$store.commit('cart/setDetailCart', {
            item,
            product,
          })
          this.isLoadingEdit = false
          this.$bvModal.show('modal-edit-product')
        }).catch(err => {
          this.isLoadingEdit = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
      }
    },
    async createItemToCart() {
      this.isLoading = true
      this.formPayload.customer_uuid = this.formPayloadBatch.customer_uuid
      if (this.$store.state.cart.isEdit) {
        this.formPayload.item_uuid = this.id
      }
      if (this.typeWarehouse === 'warehouse') {
        delete this.formPayload.po_so_uuid
        delete this.formPayload.delivery_numbers_uuid
      }
      const form = this.preparePayload()
      await this.$store.dispatch('order/postData', {
        uuid: `${this.$route.params.id}/items`,
        payload: form,
      }).then(() => {
        successNotification(this, 'Success', `Produk berhasil ${this.$store.state.cart.isEdit === false ? 'ditambahkan' : 'diubah'} ke transaksi`)
        this.getActiveCart()
        this.isLoading = false
        this.formPayload = {}
        this.$bvModal.hide('modal-edit-product')
      }).catch(err => {
        // eslint-disable-next-line no-console
        this.isLoading = false
        this.messages = err.response.data.meta.messages
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'price' && key !== 'qty' && key !== 'discounts' && key !== 'delivery_numbers_uuid' && key !== 'warehouse_uuid' && key !== 'supplier') {
            form.append(key, this.formPayload[key])
          }
           if (key === 'price' || key === 'qty') {
            // eslint-disable-next-line radix
            form.append(key, parseFloat(this.preformatFloat(this.formPayload[key].toString())))
          }
        }
      }
      if (this.typeWarehouse === 'warehouse') {
        form.append('warehouse_uuid', this.formPayload.warehouse_uuid)
      }
      if (this.typeWarehouse === 'supplier') {
        form.append('supplier_uuid', this.formPayload.warehouse_uuid)
      }
      for (let index = 0; index < this.formPayload.discounts.length; index++) {
        const element = this.formPayload.discounts[index]
        // eslint-disable-next-line no-restricted-syntax
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            const items = element[key]
            form.append(`discounts[${index}][${key}]`, items)
          }
        }
      }
      if (this.formPayload.delivery_numbers_uuid) {
        for (let index = 0; index < this.formPayload.delivery_numbers_uuid.length; index++) {
          const element = this.formPayload.delivery_numbers_uuid[index]
          form.append('delivery_numbers_uuid[]', element.label)
        }
      }
      return form
    },
    preparePayloadBatch() {
      const form = new FormData()

      for (let index = 0; index < this.additional_costs.length; index++) {
        const element = this.additional_costs[index];
        const keys = Object.keys(element)
        keys.forEach(key => {
          form.append(`additional_fees[${index}][${key}]`, key == 'additional_fee_note' ? element[key] : parseFloat(typeof element[key] == 'number' ? element[key] : preformatFloat(element[key])))
        });
      }

      for (let i = 0; i < this.formPayloadBatch.items.length; i++) {
        const element = this.formPayloadBatch.items[i]
        const keys = Object.keys(element)
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j]
          if (key !== 'discounts' && key !== 'delivery_numbers_uuid' && key !== 'warehouse_uuid' && key !== 'supplier_uuid' && element[key] !== null) {
            form.append(`items[${i}][${key}]`, element[key])
          }
          if (key === 'discounts' && element.discounts.length > 0) {
            for (let k = 0; k < element.discounts.length; k++) {
              const discount = element.discounts[k]
              const keyDiscount = Object.keys(discount)
              for (let l = 0; l < keyDiscount.length; l++) {
                const keyD = keyDiscount[l]
                form.append(`items[${i}][discounts][${k}][${keyD}]`, discount[keyD])
              }
            }
          }
          if (this.typeWarehouse === 'warehouse' && element.warehouse_uuid) {
            form.append(`items[${i}][warehouse_uuid]`, element.warehouse_uuid)
          }
          if (this.typeWarehouse === 'supplier' && element.supplier_uuid) {
            form.append(`items[${i}][supplier_uuid]`, element.supplier_uuid)
          }
          if (key === 'delivery_numbers_uuid' && element.delivery_numbers_uuid.length > 0) {
            for (let k = 0; k < element.delivery_numbers_uuid.length; k++) {
              const deliveries = element.delivery_numbers_uuid[k]
              const keyDelivery = Object.keys(deliveries)
              for (let l = 0; l < keyDelivery.length; l++) {
                const keyD = keyDelivery[l]
                form.append(`items[${i}][delivery_numbers_uuid][${k}]`, deliveries[keyD])
              }
            }
          }
        }
      }

      return form
    },
    removeCart(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this cart?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('order/postData', {
            uuid: `${id}/delete`,
            payload: '',
          }).then(res => {
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Produk berhasil dihapus',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.getActiveCart()
          }).catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })
        }
      })
    },
    changeValue(index, id) {
      let value = parseInt(document.getElementById(`number-${index}`).value, 10)
      // eslint-disable-next-line no-restricted-globals
      value = isNaN(value) ? 0 : value
      document.getElementById(`number-${index}`).value = value
      this.formQty[index].qty = value
      this.formPayloadBatch.items[index].qty = value
      this.cashTotalPayment = this.formPayloadBatch.items[index].price * value
    },
    increaseValue(index, id) {
      let value = parseInt(document.getElementById(`number-${index}`).value, 10)
      // eslint-disable-next-line no-restricted-globals
      value = isNaN(value) ? 0 : value
      // eslint-disable-next-line no-plusplus
      value++
      document.getElementById(`number-${index}`).value = value
      this.formQty[index].qty = value
      this.formPayloadBatch.items[index].qty = value
      this.cashTotalPayment = this.formPayloadBatch.items[index].price * value
    },
    decreaseValue(index, id) {
      let value = parseInt(document.getElementById(`number-${index}`).value, 10)
      // eslint-disable-next-line no-restricted-globals
      value = isNaN(value) ? 0 : value
      // eslint-disable-next-line no-unused-expressions
      value < 1 ? value = 1 : ''
      // eslint-disable-next-line no-plusplus
      value--
      document.getElementById(`number-${index}`).value = value
      this.formQty[index].qty = value
      this.formPayloadBatch.items[index].qty = value
      this.cashTotalPayment = this.formPayloadBatch.items[index].price * value
    },
    redirectPage() {
      this.$router.go(-1)
      return false
    },
    getRupiah(index) {
      let rupiah = document.getElementById(`nominal-biaya-${index}`)
      rupiah.value = this.formatRupiah(rupiah.value.toString(), '')
      this.additional_costs[index].additional_fee_amount = rupiah.value
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, '').toString()
      const split = number_string.split(',')
      const sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }

      rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah
      return prefix === undefined ? rupiah : (rupiah || '')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.bg-wave {
  background-image: url('../../..//assets/images/wave-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  &.bg-white {
    background-color: #FFFFFF !important;
    border: 1px solid $light--3 !important;
    color: $dark !important;
  }
}
.floating__button {
  position: sticky;
  bottom: 0;
  width: 445px;
  @media screen and (min-width: 1280px) {
    position: fixed;
    width: 538px;
    bottom: 0;
  }
}
</style>
