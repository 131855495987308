<template>
  <b-modal
    id="modal-additional-input-discount"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
  >
    <div class="p-2 d-flex justify-content-between">
      <div />
      <h1 class="h4 text-dark fw-bold-700 mb-0 size16">
        Masukkan diskon tambahan % atau Rp
      </h1>
      <feather-icon
        class="text-dark fw-bold-700"
        size="20"
        icon="XIcon"
        @click="$bvModal.hide('modal-additional-input-discount')"
      />
    </div>
    <hr class="mt-0">
    <b-container>
      <b-row class="p-2">
        <b-col cols="12">
          <b-form-group>
            <label for="jumlah-diskon">Jumlah diskon</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-if="formPayload.discount_type == 'percent'"
                id="jumlah-diskon"
                v-model="formPayload.discount_value"
                type="number"
                max="100"
                min="0"
                placeholder="Diskon"
              />
              <b-form-input
                v-else
                v-model="formPayload.discount_value"
                v-money="money"
              />
              <b-input-group-append is-text>
                <div class="button__group">
                  <b-button
                    class="button__is--discount"
                    :class="{'active' : formPayload.discount_type == 'percent'}"
                    @click="formPayload.discount_type = 'percent'"
                  >
                    %
                  </b-button>
                  <b-button
                    class="button__is--discount"
                    :class="{'active' : formPayload.discount_type == 'fixed'}"
                    @click="formPayload.discount_type = 'fixed'"
                  >
                    Rp
                  </b-button>
                </div>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <div class="button__group">
      <b-button
        class="button__cancel"
        @click="$bvModal.hide('modal-additional-input-discount')"
      >
        Batal
      </b-button>
      <b-button
        v-if="isStock == false"
        class="button__save"
        @click="$store.state.cashier.isAddDiscount == false ? saveDiscount() : addDiscount()"
      >
        Simpan
      </b-button>
      <b-button
        v-if="isStock == true"
        class="button__save"
        @click="addDiscount"
      >
        Simpan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
// import { ValidationProvider } from 'vee-validate'
import {
  BFormInput, BRow, BCol, BModal, VBToggle, BButton, BFormGroup, BContainer, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    isStock: {
      type: Boolean,
    },
    formData: {
      type: Object,
    },
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      is_ritel: true,
      is_discount: false,
      formPayload: {
        discount_type: 'percent',
        discount_value: '',
      },
    }
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  watch: {
    '$store.state.cashier.discounts': function (value) {
      this.formPayload = {
        discount_type: value.type,
        discount_value: value.discount,
      }
    },
    formData(value) {
      this.formPayload = {
        discount_type: value.type,
        discount_value: value.discount || '',
      }
    },
  },
  mounted() {
    this.clearItem()
  },
  destroyed() {
    this.clearItem()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    addDiscount() {
      if (this.formPayload.discount_type == 'percent' && this.formPayload.discount_value > 100) {
        this.$swal({
          icon: 'error',
          title: 'Oops!',
          text: 'Maksimal diskon 100%',
        })
        return false
      }
      if (this.formPayload.discount_type == 'percent' && this.formPayload.discount_value <= 100) {
        if (this.isStock == true) {
          this.$store.commit('stockWarehouse/setDiscounts', {
            type: this.formPayload.discount_type,
            discount: this.formPayload.discount_value,
          })
          this.clearItem()
        } else {
          this.$store.commit('cashier/setDiscounts', {
            type: this.formPayload.discount_type,
            discount: this.formPayload.discount_value,
          })
          this.clearItem()
        }
        this.$bvModal.hide('modal-additional-input-discount')
        return true
      }
      if (this.formPayload.discount_type == 'fixed') {
        if (this.isStock == true) {
          this.$store.commit('stockWarehouse/setDiscounts', {
            type: this.formPayload.discount_type,
            discount: this.formPayload.discount_value,
          })
          this.clearItem()
        } else {
          this.$store.commit('cashier/setDiscounts', {
            type: this.formPayload.discount_type,
            discount: this.formPayload.discount_value,
          })
          this.clearItem()
        }
        this.clearItem()
        this.$bvModal.hide('modal-additional-input-discount')
        return true
      }
      this.formPayload = {
        discount_type: 'percent',
        discount_value: '',
      }
      // this.formPayload = {
      //   discount_type: 'percent',
      //   discount_value: '',
      // }
      // this.clearItem()
    },
    clearItem() {
      this.formPayload = {
        discount_type: 'percent',
        discount_value: '',
      }
      return true
    },
    saveDiscount() {
      // eslint-disable-next-line radix
      const price = parseInt(this.formPayload.discount_value.toString().replace('.', ''))
      this.$store.dispatch('order/postData', {
        uuid: `${this.$route.params.id}/discount` || '',
        payload: {
          discount_type: this.formPayload.discount_type,
          discount_value: price,
        },
      }).then(result => {
        this.$store.commit('cart/setDiscountCart', result.data.data.discount_value)
        this.$store.commit('cart/setDiscountType', result.data.data.discount_type)
        this.$store.commit('cart/setDiscountTotal', result.data.data.discount_total)
        this.$store.commit('cart/setSubtotalCart', result.data.data.subtotal)
        this.$store.commit('cart/setTotalPrice', result.data.data.total)
        this.$store.commit('cart/setTotalBuy', result.data.data.items.length)
        this.$store.commit('cart/setCartUuid', result.data.data.uuid)
        this.$store.commit('cart/setResultCartActive', result.data.data)
        this.$store.commit('cashier/setProduct', result.data.data.items.length)
        localStorage.setItem('cartUuid', result.data.data.uuid)
        localStorage.setItem('cash_total_payment', result.data.data.total)
        successNotification(this, 'Success', 'Diskon telah ditambahkan ke cart')
        this.$bvModal.hide('modal-additional-input-discount')
      }).catch(err => {
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '/src/assets/scss/variables/_variables.scss';

#modal-additional-input-discount {
  .modal-dialog {

    .modal-content {
      background: #FCFCFC;
      border-radius: 16px;

      .modal-body {
        padding: 0;
        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }
          input {
            background: $light--background;
            border: none;
            border-radius: 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
          .input-group {
            .input-group-append {
              .input-group-text {
                border: 1px solid $light--background;
                background-color: $light--background;
                color: $light--description;
                border-radius: 8px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
          }
        }
        .button__group {
          display: flex;
          width: 100%;

          .button__is--discount {
            background: transparent !important;
            border: none !important;
            color: $light--description !important;

            &.active {
              background: #FFFFFF !important;
              border: none !important;
              color: $dark !important;
            }
          }

          .button__cancel {
            background-color: #FFFFFF !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45B6AB !important;
            border-color: #45B6AB !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

        }
      }
    }
  }
}
</style>
