<template>
  <b-modal
    :id="`modal-edit-${modalKey}`"
    hide-header
    hide-footer
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
  >
    <div class="p-2 d-flex justify-content-between">
      <feather-icon
        class="text-dark font-weight-bolder"
        style="cursor: pointer;"
        size="20"
        icon="ArrowLeftIcon"
        @click="$bvModal.hide(`modal-edit-${modalKey}`)"
      />
      <h1 class="size14 fw-bold-600 text-dark mb-0">
        {{ product.name || '' }} - {{ product.stock || '' }} {{ product.unit || '' }}
      </h1>
      <div />
    </div>
    <hr class="my-0">
    <div style="overflow: scroll;height: calc(100vh - 173px);padding-bottom: 2rem;">
      <b-row class="mt-3 px-2 mx-0 justify-content-between align-items-center" v-if="isDefault === false">
        <b-col
          lg="7"
          class="pr-1 pl-0"
        >
          <h6 class="text-dark font-weight-bolder">
            Pilih Lokasi SKU
          </h6>
        </b-col>
        <b-col
          lg="5"
          class="px-0"
        >
          <b-form-group class="mb-0 custom__form--input">
            <b-form-select
              v-model="formPayload.warehouse_uuid"
              :options="options"
              text-field="title"
              placeholder="Pilih Lokasi SKU"
              @change="selectOnChange(options)"
            />
            <small
              v-if="message.warehouse_uuid && Array.isArray(message.warehouse_uuid) && message.warehouse_uuid.length > 0"
              class="text-danger"
            >{{ message.warehouse_uuid.join(', ') }}</small>
          </b-form-group>
        </b-col>
        <b-col
          class="px-0"
          lg="12"
        >
          <hr class="">
        </b-col>
        <transition name="fade" mode="out-in">
          <b-col
            v-if="optionsPo.length > 0"
            lg="12"
            class="px-0 mb-1"
          >
            <b-form-group class="mb-0 custom__form--input">
              <label>Pilih Nomor PO/SO <span class="text-danger">*</span></label>
              <b-form-select
                v-model="formPayload.po_so_uuid"
                :options="optionsPo"
                text-field="title"
                @change="selectPoId(optionsPo)"
              />
              <small
                v-if="message.po_so_id && Array.isArray(message.po_so_id) && message.po_so_id.length > 0"
                class="text-danger"
              >{{ message.po_so_id.join(', ') }}</small>
            </b-form-group>
          </b-col>
        </transition>
        <transition name="fade" mode="out-in">
          <b-col
            v-if="optionsPo.length > 0 && optionsDeliveryNumber.length > 0"
            lg="12"
            class="px-0"
          >
            <b-form-group class="mb-0 custom__form--input">
              <label>Pilih Nomor Surat Jalan <span class="text-danger">*</span></label>
              <v-select
                v-model="formPayload.delivery_numbers_uuid"
                multiple
                label="title"
                :options="optionsDeliveryNumber"
              />
              <small
                v-if="message.delivery_numbers_uuid && Array.isArray(message.delivery_numbers_uuid) && message.delivery_numbers_uuid.length > 0"
                class="text-danger"
              >{{ message.delivery_numbers_uuid.join(', ') }}</small>
            </b-form-group>
          </b-col>
        </transition>
      </b-row>

      <div
        class="container px-0"
        :class="isDefault === true ? 'mt-5 pt-5' : ''"
      >
        <b-row class="px-2 mx-0 mt-2">
          <b-col
            lg="7"
            class="px-0 d-flex justify-content-center flex-column"
          >
            <h6 class="text-dark font-weight-bolder mb-0">
              Jumlah Penjualan
            </h6>
            <small class="text-dark text-darken-5" v-if="isDefault === false">
              Stok Tersedia : {{ stock || '-' }} {{ product.unit || '' }}
            </small>
          </b-col>
          <b-col
            lg="5"
            class="px-0"
          >
            <div id="tour-qty" class="spinner__button spinner__button--addproduct">
              <div
                id="decrease"
                class="value-button text-dark font-weight-bolder"
                value="Decrease Value"
                @click="decreaseValue"
              >
                -
              </div>
              <input
                v-if="isDefault === false"
                id="qty"
                class="qty text-dark font-weight-bolder"
                type="text"
                :value="formPayload.qty"
                @input="getQty"
                @change="getQty"
              >
              <input
                v-else
                id="qty"
                class="qty text-dark font-weight-bolder"
                type="text"
                :value="formPayload.qty"
                @input="getQty"
                @change="getQty"
              >
              <!-- :max="stock" -->
              <div
                id="increase"
                class="value-button text-dark font-weight-bolder"
                value="Increase Value"
                @click="increaseValue"
              >
                +
              </div>
            </div>
            <small
              v-if="message.qty && Array.isArray(message.qty) && message.qty.length > 0"
              class="text-danger"
            >{{ message.qty.join(', ') }}</small>
          </b-col>
          <!-- Harga Barang -->
          <b-col
            class="px-0 mt-2"
            lg="12"
          >
            <div class="custom__form--input">
              <label for="harga-barang">
                Harga Barang <span class="text-danger font-weight-bolder">*</span>
              </label>
              <b-input-group
                id="tour-price"
                prepend="Rp"
                :append="`Min Rp ${minPriceFormatted}`"
              >
                <b-form-input
                  id="harga-barang"
                  :value="formPayload.price"
                  type="text"
                  class="size24 custom__input pl-2"
                  style="width: 65%;"
                  @input="getRupiah"
                  @change="getRupiah"
                />
              </b-input-group>
            </div>
            <small
              v-if="message && Array.isArray(message) && message.length > 0"
              class="text-danger"
            >{{ message.join(', ') }}</small>
          </b-col>
          <b-col
            lg="12"
            class="mt-1 px-0"
          >
            <b-card
              class="border-8 p-0 mb-0 w-100"
              style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);"
              no-body
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <div
                  id="tour-discount"
                  class="d-flex align-items-center justify-content-between px-2 py-1 w-100"
                  @click="addDiscount"
                >
                  <div class="d-flex align-items-center">
                    <div class="rounded__frame">
                      <b-img
                        width="17"
                        :src="require('@/assets/images/icons/discount.svg')"
                      />
                    </div>
                    <h6 class="text-primary font-weight-bolder mb-0">
                      Masukkan diskon tambahan % atau Rp
                    </h6>
                  </div>
                  <feather-icon
                    class="text-dark font-weight-bolder"
                    size="20"
                    icon="PlusIcon"
                  />
                </div>
              </div>
              <b-row class="mt-1" v-if="discounts.length > 0">
                <b-col cols="12">
                  <hr>
                </b-col>
                <b-col
                  v-for="(item, index) in discounts"
                  :key="index"
                  cols="12"
                  class="mb-1"
                >
                  <div class="d-flex px-2 align-items-center">
                    <h6
                      class="mb-0 w-50"
                    >
                      {{ index + 1 }}. Diskon {{ item.type === 'fixed' ? 'Rp' : '%' }}
                    </h6>
                    <b-form-input
                      v-money="money"
                      class="w-100"
                      :value="item.discount"
                      readonly
                      disabled
                    />
                    <div
                      class="align-self-center text-right ml-1"
                      role="button"
                      @click="editDiscount(index, item.type, item.discount)"
                    >
                      <feather-icon
                        size="24"
                        icon="EditIcon"
                        class="text-primary fw-bold-800"
                      />
                    </div>
                    <div
                      class="align-self-center text-right ml-1"
                      role="button"
                      @click="deleteDiscount(index)"
                    >
                      <feather-icon
                        size="24"
                        icon="Trash2Icon"
                        class="text-danger fw-bold-800"
                      />
                    </div>
                  </div>
                  <hr class="mb-0 mt-1">
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="floating__button">
      <b-card
        no-body
        class="card__discount mb-0 p-0"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center justify-content-between px-2 py-1 w-100">
            <div class="d-flex align-items-center">
              <div class="rounded__frame">
                <b-img
                  width="17"
                  :src="require('@/assets/images/icons/bonus.svg')"
                />
              </div>
              <h6 class="text-primary font-weight-bolder mb-0">
                Centang untuk dijadikan bonus
              </h6>
            </div>
            <b-form-checkbox
              id="tour-bonus"
              v-model="formPayload.is_bonus"
              class="custom-control-primary"
              name="bonus"
              value="1"
              unchecked-value="0"
              switch
              inline
            />
            <small
              v-if="message.is_bonus && Array.isArray(message.is_bonus) && message.is_bonus.length > 0"
              class="text-danger"
            >{{ message.is_bonus.join(', ') }}</small>
          </div>
        </div>
      </b-card>
      <div class="d-flex border-top">
        <div class="p-1 p-md-2 p-lg-2 w-100 bg-white">
          <small
            v-if="formPayload.is_bonus == 1"
            class="text-dark text-darken-4 size14"
            :class="{'text-line-through' : formPayload.is_bonus == 1}"
          >{{ formPayload.price | formatAmount }}</small>
          <h1 class="h6 size20 font-weight-bolder mb-0" v-if="formPayload.is_bonus == 1">
            Rp 0,00
          </h1>
          <h1 class="h6 size20 font-weight-bolder mb-0" v-else>
            {{ total | formatAmount }}
          </h1>
        </div>
        <div
          class="p-1 p-md-2 p-lg-2 btn-save"
          role="button"
          :disabled="isLoading"
          @click="createItemToCart"
        >
          <h1 class="h6 size16 font-weight-bolder mb-0">
            <b-spinner
              v-if="isLoading"
              class="mr-1"
              variant="primary"
            />
            Simpan
          </h1>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BSpinner, VBToggle, BCard, BImg, BInputGroup, BFormInput, BFormCheckbox, BRow, BCol, BFormGroup, BInputGroupAppend, BFormSelect,
} from 'bootstrap-vue'
import { preformatFloat } from '@/auth/utils'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BCard,
    BImg,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BInputGroupAppend,
    BFormSelect,
    BSpinner,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup() {
    return {
      preformatFloat,
    }
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    formData: {
      type: Object,
    },
    result: {
      type: Object,
    },
    createItemToCart: {
      type: Function,
    },
    messages: {
      type: '',
    },
    loading: {
      type: Boolean,
    },
    modalKey: {
      type: String,
    },
    isDefault: {
      type: Boolean,
    },
    stockWarehouse: {
      type: Number,
    },
    typeWarehouses: {
      type: String,
    },
  },
  data() {
    return {
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      formPayload: {
        delivery_numbers_uuid: [],
        po_so_uuid: '',
        product_uuid: '',
        warehouse_uuid: '',
        qty: '0',
        price: '0',
        discounts: [],
        is_bonus: 0,
      },
      discounts: [],
      discount: {},
      product: {},
      is_bonus: true,
      is_ritel: true,
      options: [
        {
          value: '',
          title: 'Pilih Lokasi SKU',
          stock: '',
        },
      ],
      typeWarehouse: '',
      optionsPo: [],
      optionsDeliveryNumber: [],
      isLoading: false,
      stock: 0,
      min_price: 0,
      outputDiscount: 0,
      total: 0,
      message: '',
      qty: 0,
    }
  },
  computed: {
    minPriceFormatted() {
      return this.min_price > 0 ? Number(this.min_price).toLocaleString().replace(/,/g, '.') : 0
    },
    totalDiscount() {
      return this.total
    },
  },
  watch: {
    '$store.state.cart.detailCart': {
      handler(value) {
        this.message = ''
        this.options = []
        this.optionsPo = []
        this.optionsDeliveryNumber = []
        let po_so = []

        for (let index = 0; index < value.item?.stock_in_warehouse?.length; index++) {
          const element = value.item?.stock_in_warehouse[index]
          this.options.push({
            value: element.uuid,
            title: `${element.name} (Stok: ${element.stock})`,
            stock: element.stock < 0 ? '' : element.stock,
            type: element.type,
            po_so: element.po_so || null,
          })

          if (element.uuid === value.product.warehouse?.uuid) {
            this.stock = element.stock
          }
        }

        if (this.modalKey === 'product') {
          if (value.product?.delivery_numbers) {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < value.product?.delivery_numbers?.length; index++) {
              const element = value.product?.delivery_numbers[index]
              this.optionsDeliveryNumber.push({
                title: element.delivery_number_note,
                label: element.uuid,
              })
            }
          }

          for (let index = 0; index < value.item?.stock_in_warehouse?.length; index++) {
            const element = value.item?.stock_in_warehouse[index]
            if (value.product.supplier?.uuid === element.uuid) {
              po_so = element.po_so
            }
          }

          this.optionsPo = po_so.map(supplier => ({
            value: supplier.uuid,
            title: supplier.po_so_id,
            delivery_numbers: supplier.delivery_numbers,
          }))
        }

        if (value.product.warehouse !== null) {
          this.$emit('typeWarehouse', 'warehouse')
        } else {
          this.$emit('typeWarehouse', 'supplier')
        }

        this.$store.commit('cart/setItemUuid', value.product?.uuid)

        // this.formPayload = {
        //   delivery_numbers_uuid: value.product && value.product.delivery_numbers ? value.product?.delivery_numbers.map(element => ({ title: element.delivery_number_note, label: element.uuid })) : [] || [],
        //   product_uuid: value.product?.product?.uuid,
        //   po_so_uuid: value.product?.po_so?.uuid || null,
        //   warehouse_uuid: value.product?.warehouse ? value.product?.warehouse?.uuid : value.product?.supplier?.uuid,
        //   qty: value.product?.qty,
        //   price: value.product?.sub_price,
        //   discounts: value.product?.discounts,
        //   is_bonus: value.product?.is_bonus === false ? 0 : 1,
        // }

        this.min_price = value.item?.minimum_price
        this.product.name = value.item?.name
        this.product.stock = value.item?.stock
        this.product.unit = value.item?.unit
      },
      deep: true,
    },
    messages(value) {
      this.message = value
    },
    loading(value) {
      this.isLoading = value
    },
    discounts() {
      this.summaryDiscount()
    },
    '$store.state.cashier.discounts': {
      handler(value) {
        if (value.discount !== '') {
          if (this.modalKey === 'product') {
            if (this.$store.state.cashier.indexDiscounts !== null) {
              this.discounts.splice(this.$store.state.cashier.indexDiscounts, 1)
              this.discounts.splice(this.$store.state.cashier.indexDiscounts, 0, {
                type: value.type,
                discount: value.discount.toString().replace('.', ''),
              })
              this.summaryDiscount()
            }
            if (this.$store.state.cashier.indexDiscounts === null) {
              if (this.$store.state.cashier.isAddDiscount === true) {
                this.discounts.push({
                  type: value.type,
                  discount: value.discount.toString().replace('.', ''),
                })
                this.summaryDiscount()
              }
            }
          }
        }
        this.summaryDiscount()
      },
      deep: true,
    },
    'formPayload.qty': function (value) {
      this.qty = value || '0'
      this.formPayload.qty = value
      this.summaryDiscount()
    },
    // 'formPayload.price': function (value) {
    //   if (value) {
    //     const price = value.toString().replace(/\./g, '')
    //     // eslint-disable-next-line radix
    //     this.formPayload.price = parseInt(price)
    //     this.summaryDiscount()
    //   }
    // },
    result: {
      handler(value) {
        this.options = []
        this.optionsPo = []
        this.optionsDeliveryNumber = []
        this.min_price = value.minimum_price
        this.product = value

        // eslint-disable-next-line no-plusplus
        if (this.isDefault === false) {
          for (let index = 0; index < value.stock_in_warehouse.length; index++) {
            const element = value.stock_in_warehouse[index]
            if (index === 0) {
              this.formPayload.warehouse_uuid = element.uuid
            }
            this.options.push({
              value: element.uuid,
              title: `${element.name} (Stok: ${element.stock})`,
              stock: element.stock < 0 ? '' : element.stock,
              type: element.type,
              po_so: element.po_so || null,
            })
          }
        }
        this.options.unshift({ value: '', title: 'Pilih Lokasi SKU', stock: 0 })
      },
      deep: true,
    },
    formData: {
      handler(value) {
        this.formPayload = value
        this.discounts = value.discounts
      },
      deep: true,
    },
    optionsPo(value) {
      if (value.length === 0) {
        this.formPayload = {
          ...this.formPayload,
          delivery_numbers_uuid: [],
          po_so_uuid: '',
        }
      }
    },
    formPayload: {
      handler(value) {
        this.$emit('payload', value)
      },
      deep: true,
    },
    stockWarehouse(value) {
      this.stock = value < 0 ? '' : value
    },
    typeWarehouses(value) {
      this.typeWarehouse = value
    },
  },
  methods: {
    getQty() {
      const qty = document.getElementById('qty')
      qty.value = this.formatRupiah(qty.value, '')
      this.formPayload.qty = qty.value
      this.summaryDiscount()
    },
    getRupiah() {
      const rupiah = document.getElementById('harga-barang')
      rupiah.value = this.formatRupiah(rupiah.value, '')
      this.formPayload.price = rupiah.value
      this.summaryDiscount()
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, '').toString()
      const split = number_string.split(',')
      const sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah
      return prefix == undefined ? rupiah : (rupiah || '')
    },
    editDiscount(index, type, discount) {
      this.$store.commit('cashier/setIndexDiscounts', index)
      this.$store.commit('cashier/setDiscounts', {
        type,
        discount,
      })
      this.$store.commit('cashier/setIsAddDiscount', true)
      this.$bvModal.show('modal-additional-input-discount')
    },
    deleteDiscount(index) {
      this.discounts.splice(index, 1)
      this.summaryDiscount()
    },
    addDiscount() {
      this.$store.commit('cashier/setDiscounts', {
        type: 'percent',
        discount: '',
      })
      this.$store.commit('cashier/setIndexDiscounts', null)
      this.$store.commit('cashier/setIsAddDiscount', true)
      this.$bvModal.show('modal-additional-input-discount')
    },
    summaryDiscount() {
      try {
        let { price, qty } = this.formPayload
        price = typeof price === 'number' ? price : parseFloat(this.preformatFloat(price))
        qty = typeof qty === 'number' ? qty : parseFloat(this.preformatFloat(qty))
        // eslint-disable-next-line no-restricted-syntax, no-plusplus
        for (let index = 0; index < this.discounts.length; index++) {
          const element = this.discounts[index]
          if (element.type === 'fixed' && element.discount > 0) {
            // eslint-disable-next-line radix
            const fixedPrice = element.discount
            price -= fixedPrice
          } else if (element.type === 'percent' && element.discount > 0) {
            // eslint-disable-next-line no-mixed-operators, radix, operator-assignment
            price = (((100 - element.discount)) / 100) * price
          }
        }
        this.total = price * qty
      } catch (error) {
        console.log(error)
      }
    },
    selectPoId(event) {
      this.optionsDeliveryNumber = []
      event.forEach(element => {
        if (element.value === this.formPayload.po_so_uuid) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < element.delivery_numbers.length; index++) {
            const item = element.delivery_numbers[index]
            this.optionsDeliveryNumber.push({
              title: item.delivery_number_note,
              label: item.uuid,
            })
          }
        }
      })
    },
    selectOnChange(event) {
      this.optionsPo = []
      this.optionsDeliveryNumber = []
      this.formPayload.qty = 1
      event.forEach(element => {
        if (element.value === this.formPayload.warehouse_uuid) {
          this.typeWarehouse = element.type
          this.$emit('typeWarehouse', element.type)
          if (element.po_so) {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < element.po_so.length; index++) {
              const item = element.po_so[index]
              this.optionsPo.push({
                value: item.uuid,
                title: item.po_so_id,
                delivery_numbers: item.delivery_numbers,
              })
            }
          }
          this.stock = element.stock
        }
      })
    },
    changeValue() {
      const qty = document.getElementById('qty').value
      this.formPayload.qty = parseFloat(qty)
      this.summaryDiscount()
    },
    increaseValue() {
      this.qty = typeof this.qty === 'number' ? this.qty : parseFloat(this.preformatFloat(this.qty))
      this.qty = +(this.qty + 0.1).toFixed(1)
      this.formPayload.qty = this.formatRupiah(this.qty.toString().replace('.', ','), '')
      document.getElementById('qty').value = this.formPayload.qty
      this.summaryDiscount()
    },
    decreaseValue() {
      this.qty = typeof this.qty === 'number' ? this.qty : parseFloat(this.preformatFloat(this.qty))
      if (this.qty > 0) {
        this.qty = +(this.qty - 0.1).toFixed(1)
      }
      this.formPayload.qty = this.formatRupiah(this.qty.toString().replace('.', ','), '')
      this.summaryDiscount()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '/src/assets/scss/variables/_variables.scss';

#modal-edit-product, #modal-edit-search {
  .grid-container .grid-item.option-number:last-child {
    border-radius: 0px 16px 0px 0px;
  }
}
#modal-edit-product, #modal-edit-search {
  .modal-dialog {
    position: absolute;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 453px;

    .modal-content {
      background: #FCFCFC;
      border-radius: 16px 16px 0px 0px;

      .modal-body {
        height: 100vh;
        overflow: scroll;
        padding: 0;

        @media screen and (max-width: 800px) and (orientation: landscape) {
          height: 97vh;
          overflow-y: scroll;
        }

        .floating__button {
          position: sticky;
          width: 100%;
          bottom: 0;

          > div:hover {
            cursor: pointer;
          }

          .btn-save {
            display: flex;
            align-items: center;
            background: #45B6AB;
            justify-content: center;
            width: 17.576rem;

            h1 {
              color: #FFFFFF !important;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .custom__form--input {
          .form-group {
            margin-bottom: 0;
          }

          label {
            font-size: 14px;
            font-weight: 600;
            color: $dark;
          }

          .form-control {
            background: $light--1;
            border: none;
            box-sizing: border-box;
            border-radius: 8px;
            height: 53px;
            color: $dark;
            font-weight: 600;
            font-size: 14px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .vs__dropdown-toggle {
          background: $light--background;
          border-radius: 8px;
          border: none;
          height: 53px;

          .vs__actions {
            padding: 4px 14px 0 3px;
          }

          .vs__selected {
            margin: 0 !important;
          }
          .vs__search, .vs__search:focus {
            margin: 0;
          }
        }

        .spinner__button.spinner__button--addproduct {
          height: 53px;
        }

        .input-group {

          input {
            background: $light--background;
          }

          .input-group-append {
            .input-group-text {
              border: 1px solid $light--background;
              background-color: $light--background;
              color: $light--description;
              border-radius: 8px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
          .input-group-prepend {
            .input-group-text {
              border: 1px solid $light--background;
              background-color: $light--background;
              color: $light--description;
              border-radius: 8px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }
  }
}
</style>
